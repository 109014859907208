import { LOGO_IMAGES, BACK_BUTTON_IMAGES } from '../../Helpers/ImageHelper';
import CoworkingspaceClient from '../coworkingspace/CoworkingspaceClient';

/**
 * Decide the host configuration based on the current hostname.
 * The function returns a JSON object containing the host configuration.
 * If the configuration cannot be loaded, an empty object is returned.
 */
function decideHost() {
    const hostname = window.location.hostname;
    const registeredHost = hostname.split('.')[0];
    const url = `/resources/tenants/${registeredHost}.json`;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, false); 
    xhr.send(null);

    if (xhr.status === 200) {
        return JSON.parse(xhr.responseText);
    } else {
        console.error(`Error loading coworking space config from /resources/tenants/${registeredHost}.json`, xhr.statusText);
        return {}; // return an empty object in case of an error
    }
}

export const coworkingSpaceObject = new CoworkingspaceClient(decideHost()); // todo: need check from web service 
export const coworkingSpace = coworkingSpaceObject.getCoworkingspace();

export function getColorTheme() {
    // todo: need take img from string
    let themeObj = coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.COLOR_THEME);

    // themeObj.logo = LOGO_IMAGES[`${coworkingSpace}`].logo; // maybe need for dark theme later
    themeObj.logoActive = LOGO_IMAGES[`${coworkingSpace}`].logoActive;

    return themeObj;
}

export function getHeaderLogoStyle() {
    return coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.LOGO);
}

export function getHeaderProfileStyle() {
    return coworkingSpaceObject.getStyle(CoworkingspaceClient.STYLE.PROFILE_HEADER);
}

export function getBackImage() {
    return BACK_BUTTON_IMAGES[`${coworkingSpace}`].logoActive;
}

export function getHeaderList() {
    return coworkingSpaceObject.getHeaderInfo(CoworkingspaceClient.HEADER.HEADER_LIST);
}

export function getHeadersMarginStyle(index) {
    const marginArray = coworkingSpaceObject.getHeaderInfo(CoworkingspaceClient.HEADER.MARGIN_LEFT);
    return marginArray[`${index}`];
}

export function isRoomMenuAllowed() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.ROOM_MENU);
}

export function isNeedMoreAdmin() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.MORE_ADMIN);
}

export function isHaveNexudus() {
    return coworkingSpaceObject.getHaveInfo(CoworkingspaceClient.HAVE_INFO.NEXUDUS);
}

export function getBorderWithTheme(borderPx, borderStyle) {
    return `${borderPx} ${borderStyle} ${getColorTheme().selectedTheme}`
}

export function getPlatformName() {
    return coworkingSpaceObject.getPlatform();
}